$screen-lg: 1600px;

@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

.tff-dialog {
  width: 600px;
  font-size: initial;
}

.tff-id-input {
  text-transform: uppercase;
}

.tff-flex-space-between {
  display: flex;
  justify-content: space-between;
  :only-child {
    margin-left: auto;
  }
}

.tff-root {
  flex-grow: 1;
}

.tff-paper {
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.tff-search-title {
  margin: 20px 0;
}

.tff-spacer {
  height: 20px;
}

%tff-wrapper {
  width: 100%;
  @include lg {
    width: 80%;
  }
  display: flex;
  justify-content: space-between;
}

.tff-formWrapper {
  @extend %tff-wrapper;
  flex-direction: column;
}

.tff-btnWrapper {
  @extend %tff-wrapper;
}

.tff-menuWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-functional-neutral-000);
}

.tff-menuButtonsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.tff-flightMenuButtons {
  padding: 5px 16px;
}

.tff-bookingHeadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

%tff-header-menu {
  width: 100%;
  background-color: var(--color-functional-neutral-000);
  border-bottom: 1px solid var(--color-functional-neutral-400);
}

.tff-menuRoot {
  @extend %tff-header-menu;
}

.tff-shrunkenHeaderMenu {
  @extend %tff-header-menu;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 20px;
}

.tff-circle {
  width: 8px;
  height: 8px;
  border: var(--color-functional-neutral-600) 2px solid;
  border-radius: 50%;
}

%tff-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--color-functional-neutral-400);
  position: relative;
  display: flex;
}

%tff-booking {
  @extend %tff-card;
  margin: 10px;
  align-items: stretch;
  flex-direction: column;
}

.tff-original-booking {
  @extend %tff-booking;
  background-color: var(--color-functional-neutral-100);
}

.tff-new-booking {
  @extend %tff-booking;
  background-color: var(--color-theme-base-200);
}

.tff-alternative-flights-card {
  @extend %tff-card;
  background-color: var(--color-functional-neutral-000);
  margin: 16px 0;
  padding: 16px;
  gap: 24px;
}

.tff-alternative-flights-card-selected {
  @extend %tff-card;
  background-color: var(--color-theme-base-200);
  margin: 16px 0;
  padding: 16px;
  gap: 24px;
}

.tff-journey-details {
  display: flex;
  gap: 80px;
}

.tff-journey-icon-container {
  margin-right: 16px;
  color: var(--color-theme-variant);
}

.tff-inline-paths {
  display: flex;
  gap: 60px;
  width: 100%;
  padding: 0 16px 0 24px;

  @media (max-width: 1125px) {
    flex-flow: column;
    gap: 20px;
  }
}

.tff-inline-flights-container {
  display: flex;
  gap: 80px;
}

.tff-horizontal-line-pos {
  position: absolute;
}

@mixin original-booking-breakpoint {
  @media (min-width: 1125px) and (max-width: 1275px) {
    @content;
  }
}

$total-line-width: 104px;

.tff-horizontal-line {
  border-color: var(--color-functional-neutral-600);
  margin: auto;

  &.flightDetails {
    margin: 8px 0;
  }

  &.stops {
    width: calc($total-line-width / 2);
    @include original-booking-breakpoint {
      width: 4vw;
    }
  }

  &.no-stops {
    width: $total-line-width;
    @include original-booking-breakpoint {
      width: 8vw;
    }
  }
}

.tff-horizontal-line-container {
  &.stops {
    text-align: center;
    margin: 8px 50px 0;
  }

  &.no-stops {
    margin: 12px 50px 0;
  }
}

.tff-d-flex {
  display: flex;
}

.tff-cabin-class-container {
  margin-left: 8px;
  display: flex;
  align-items: center;
  height: fit-content;
}

.tff-cabin-class-icon {
  color: var(--color-theme-variant);
  height: fit-content;
  margin-right: 4px;
}

.tff-text-no-wrap {
  white-space: nowrap;
}

.tff-flight-horizontal-line {
  margin-bottom: 4px;
  width: 100%;
}

.tff-back-button {
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.tff-back-button-container {
  padding-bottom: 32px;
  align-self: self-start;
}
